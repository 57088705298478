import React, { useState } from 'react';
import Form from './Form';
import { FIELD_TYPES } from './Form/Field';
import ThankYou from './ThankYou';

const FORM_ID = 'best_scholarship';
const PIPELINE_ID = 29;

const PERSONAL_DETAILS = [
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
  },
  {
    label: 'Nationality',
    name: 'Nationality (from your passport)',
    isRequired: true,
  },
  {
    label: 'Current occupation',
    name: 'Country of Residence',
    isRequired: true,
  },
  {
    label: 'Personal website (E.g. professional website or LinkedIn)',
    name: 'Personal Website',
  },
  {
    label: 'Address',
    name: 'Address Line 1',
    isRequired: true,
  },
  {
    label: 'Address line 2',
    name: 'Address Line 2',
    isRequired: true,
  },
  {
    label: 'Town',
    name: 'Town',
    isRequired: true,
  },
  {
    label: 'Country',
    name: 'Country',
    isRequired: true,
  },
  {
    label: 'Postcode',
    name: 'ZIP / Postal Code',
    isRequired: true,
  },
  {
    label: 'Telephone',
    name: 'Phone',
    isRequired: true,
  },
  {
    label: 'email',
    name: 'Email',
    isRequired: true,
  },
];

const COURSE_DETAILS = [
  // note
  {
    label: 'Have you been accepted at a college in North America?',
    isNote: true,
    model: 'deal',
    isRequired: true,
    type: FIELD_TYPES.switch,
    options: [
      { label: 'Yes', id: 'Yes' },
      { label: 'No', id: 'No' },
    ],
  },
  {
    label: 'Name of college(s) you hope to be/will be attending',
    isNote: true,
    model: 'deal',
    isRequired: true,
    type: FIELD_TYPES.text,
  },
  {
    label: 'Name of course you are/will be enrolled on in North America',
    isNote: true,
    model: 'deal',
    isRequired: true,
    type: FIELD_TYPES.text,
  },
  {
    label: 'Course start date',
    isNote: true,
    model: 'deal',
    isRequired: true,
    type: FIELD_TYPES.date,
  },
  {
    label: 'Course end date',
    isNote: true,
    model: 'deal',
    isRequired: true,
    type: FIELD_TYPES.date,
  },
  {
    label: 'Award to be gained at end of course',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
  },
];

const FINANCE = [
  // note
  {
    label: 'Annual cost of tuition',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.currency,
    model: 'deal',
  },
  {
    label: 'Cost of maintenance',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.currency,
    model: 'deal',
  },
  {
    label: 'Total Cost',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.currency,
    model: 'deal',
  },
  {
    label: 'Name of Award',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Decision Date',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.date,
    model: 'deal',
  },
  {
    label: 'Amount of award',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Amount granted (if known)',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Total',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.currency,
    model: 'deal',
  },
  {
    label: 'Amount you are able to contribute from your own personal founds',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Funding still required at date of application',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'How much are you requesting from BEST?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
];

const EDUCATION = [
  {
    label: 'Date',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.date,
    model: 'deal',
  },
  {
    label: 'Subject',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Grade',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'University',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Start Date',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.date,
    model: 'deal',
  },
  {
    label: 'End Date',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.date,
    model: 'deal',
  },
  {
    label: 'Course',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Award and Class',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Brief biography (including travel experiences)(Max 100 words)',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.textarea,
    model: 'deal',
  },
  {
    label:
      'Employment history and other relevant placements (including internships + voluntary experience etc',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Extra-curricular interests',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Why do you feel you should be awarded a BEST Scholarship?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label:
      'What are you immediate post-graduation plans and longer term career intentions? How will the experience of studying in North America help you?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label:
      'What do you think are the demands of living in North America? How do you feel you would respond to them?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label: 'Why have you chosen to do your particular course in North America?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label:
      'How do you feel your time in North America will contribute to transatlantic understanding in both the short and long therm?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
  {
    label:
      'What will you do if you are not successful in your efforts to obtain adequate funding for your proposed course in North America?',
    isNote: true,
    isRequired: true,
    type: FIELD_TYPES.text,
    model: 'deal',
  },
];
const formConfig = [
  ...PERSONAL_DETAILS,
  ...COURSE_DETAILS,
  ...FINANCE,
  ...EDUCATION,
];

const BestScholarShip = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="BEST Scholarship"
      formId={FORM_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
      pipelineId={PIPELINE_ID}
    />
  );
};

export default BestScholarShip;